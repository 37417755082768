'use client'

import { type Dispatch, type SetStateAction } from 'react'

import { useClientTranslation } from '@/app/i18n/client'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Flex,
  WarningIcon,
} from '@/components'

// アクセスロックダイアログ
export function AccessLockedDialog({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}) {
  const { t } = useClientTranslation(['common'])

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent
        // 閉じるボタンの制御
        hideCloseButton
        // ESCキーを押しても、ダイアログを閉じないようにする
        onEscapeKeyDown={(e) => e.preventDefault()}
        // ダイアログ外側をクリックしても、ダイアログを閉じないようにする
        onInteractOutside={(e) => e.preventDefault()}
        // ダイアログを開いた際に、フォーカスさせない
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>
            <Flex align="center" justify="center">
              <WarningIcon className="size-[83px] text-brand" />
            </Flex>
          </DialogTitle>
        </DialogHeader>
        <Flex align="center" direction="column" gap="4" justify="center">
          <h2 className="text-title">アクセスがロックされました</h2>

          <p>所属会社のシステム管理者へパスワードリセットを依頼してください</p>
          <Button onClick={() => setOpen(false)}>{t('buttons.ok')}</Button>
        </Flex>
      </DialogContent>
    </Dialog>
  )
}
