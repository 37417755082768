import { AuthErrorCodes } from 'firebase/auth'

export const FikaigoAuthErrorCodes = {
  FETCH_STATUS_ERROR: 'appCheck/fetch-status-error', // AppCheckエラー
  ID_TOKEN_EXPIRED: 'auth/id-token-expired', // FirebaseIDトークン期限切れ
  PROJECT_DISABLED: 'auth/project-disabled', // 無効なテナント
  REQUEST_CONTAINS_AN_INVALID_ARGUMENT: 'auth/request-contains-an-invalid-argument', // MFA認証コードエラー
  TOTP_CHALLENGE_TIMEOUT: 'auth/totp-challenge-timeout', // TOTP試行回数タイムアウト
  TOO_MANY_ENROLLMENT_ATTEMPTS: 'auth/too-many-enrollment-attempts', // MFA登録試行回数タイムアウト
  ...AuthErrorCodes,
} as const satisfies Record<string, string>
