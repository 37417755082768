'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FirebaseError } from 'firebase/app'
import { getAuth, type MultiFactorError, signInWithEmailAndPassword } from 'firebase/auth'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { type InferType, object, string } from 'yup'

import { useClientTranslation } from '@/app/i18n/client'
import {
  Button,
  Flex,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Loader,
  TextField,
} from '@/components'
import { useHandleAuthState } from '@/hooks/useHandleAuthState'
import { FikaigoAuthErrorCodes } from '@/lib/constants/errorCode'
import { ROUTES } from '@/lib/constants/routes'
import { AuthStatus } from '@/stores/useAuthStore'
import { isEmpty } from '@/utils'
import { errorHandler } from '@/utils/errors'

import { AccessLockedDialog } from './AccessLockedDialog'

const schema = object()
  .shape({
    tenantId: string().required().label('features.login.labels.tenantId'),
    id: string().required().label('features.login.labels.id'),
    password: string().required().label('features.login.labels.password'),
  })
  .required()

export function LoginWithEmailPasswordForm() {
  const auth = getAuth()
  const { t, i18n } = useClientTranslation(['common', 'validation'])
  const router = useRouter()
  const { setMfaRequiredError, setStatus } = useHandleAuthState()

  const [open, setOpen] = useState(false)
  const [accessLock, setAccessLock] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // クエリパラメータからテナントIDを取得
  const tenantIdQueryParam = useSearchParams().get('tenantId') ?? ''

  const form = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      tenantId: tenantIdQueryParam,
      id: '',
      password: '',
    },
  })

  const onSubmit = async (data: InferType<typeof schema> | null) => {
    setIsLoading(true)

    try {
      if (!data) return

      const { password, tenantId } = data

      auth.tenantId = isEmpty(tenantId) ? tenantIdQueryParam : tenantId

      const email = `${data.id}@${tenantId}.fikaigo.com`
      const { user } = await signInWithEmailAndPassword(auth, email, password)

      // ログインできたら、必ずMFA登録画面に遷移させる
      // MFA登録済みの場合は必ずエラーが発生するのでcatchで処理する
      if (user) {
        user.getIdToken()
        setStatus(AuthStatus.MFA_UNREGISTERED)
        router.push(ROUTES.MFA_REGISTER)
      }
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        switch (e.code) {
          case FikaigoAuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
          case FikaigoAuthErrorCodes.INVALID_EMAIL:
            setErrorMessage(t('features.login.message.loginFailed'))
            break
          case FikaigoAuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
            // リクエスト数が最大数を超えています。
            setOpen(true)
            setAccessLock(true)
            setErrorMessage(t('features.login.message.tooManyAttemptsTryLater'))
            break
          case FikaigoAuthErrorCodes.FETCH_STATUS_ERROR:
            setErrorMessage(t('messages.appCheck.fetchStatusError'))
            break
          case FikaigoAuthErrorCodes.MFA_REQUIRED:
            // ログインに成功すると、MFA認証必須エラーが発生するので、MFA認証画面で使用するためにエラー情報をContextにセット
            setMfaRequiredError(e as MultiFactorError)
            setStatus(AuthStatus.MFA_TOTP_REQUIRED)

            // MFA認証必須エラーが出る場合は、MFA認証登録済み、認証画面へ遷移
            router.push(ROUTES.MFA_AUTHENTICATION)
            break
          case FikaigoAuthErrorCodes.ID_TOKEN_EXPIRED:
            setErrorMessage(t('features.login.message.idTokenExpired'))
            break
          case FikaigoAuthErrorCodes.INVALID_TENANT_ID:
          case FikaigoAuthErrorCodes.PROJECT_DISABLED:
            setErrorMessage(t('features.login.message.invalidTenantId'))
            break
          case FikaigoAuthErrorCodes.USER_DISABLED:
            setErrorMessage(t('features.login.message.userDisabled'))
            break
          default:
            errorHandler({ error: e })
            break
        }
      } else {
        errorHandler({ error: e as Error })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form {...form}>
      <Flex asChild className="w-full px-15" direction="column" gap="5">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="tenantId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    autoComplete="tenantId"
                    placeholder={t('features.login.labels.tenantId')}
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormMessage i18n={i18n} />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="id"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    autoComplete="id"
                    placeholder={t('features.login.labels.id')}
                    type="text"
                    {...field}
                  />
                </FormControl>
                <FormMessage i18n={i18n} />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    autoComplete="password"
                    placeholder={t('features.login.labels.password')}
                    type="password"
                    {...field}
                  />
                </FormControl>
                <FormMessage i18n={i18n} />
              </FormItem>
            )}
          />

          <Flex direction="column" gap="4">
            {errorMessage && <p className="text-error text-center">{errorMessage}</p>}
            <Button block size="large" type="submit">
              {t('features.login.buttons.login')}
            </Button>
            <Flex justify="end">
              <Link className="text-brand" href={ROUTES.FORGOT_PASSWORD}>
                {t('features.login.buttons.forgot')}
              </Link>
            </Flex>
          </Flex>

          {accessLock && (
            <p className="text-error whitespace-pre">{t('features.login.message.accessLocked')}</p>
          )}
        </form>
      </Flex>
      <AccessLockedDialog open={open} setOpen={setOpen} />
      <Loader show={isLoading} />
    </Form>
  )
}
