'use client'

import { FikaigoBrandLogo, Flex } from '@/components'

import { LoginWithEmailPasswordForm } from '../_components/LoginWithEmailPasswordForm'

export default function Page() {
  return (
    <Flex align="center" className="h-full" direction="column" gap="5" justify="center">
      <Flex className="w-175" direction="column" gap="4">
        <div className="rounded-base py-15 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.25)]">
          <Flex align="center" direction="column" gap="10" justify="space-between">
            <FikaigoBrandLogo />
            <LoginWithEmailPasswordForm />
          </Flex>
        </div>
      </Flex>
    </Flex>
  )
}
